import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=589d900c&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=ts&"
export * from "./Button.vue?vue&type=script&lang=ts&"
import style0 from "./Button.vue?vue&type=style&index=0&id=589d900c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "589d900c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/112/a/NuxtApp/components/base/Icon.vue').default})
